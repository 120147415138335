.remove-btn {
    margin-right: 0.5rem;
}

.item-enter {
    transform: translateX(50%);
    opacity: 0;
}
.item-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.item-exit {
    opacity: 1;
    transform: translateX(0);
}
.item-exit-active {
    opacity: 0;
    transform: translateX(50%);
    transition: opacity 300ms, transform 300ms;
}