@import './mixins';

$path: '/assets/fonts/';

@include font-face('Muli-ExtraLight', $path+'Muli-ExtraLight/Muli-ExtraLight', 200);
@include font-face('Muli-Light', $path+'Muli-Light/Muli-Light', 300);
@include font-face('Muli-Regular', $path+'Muli-Regular/Muli-Regular', 400);
@include font-face('Muli-SemiBold', $path+'Muli-SemiBold/Muli-SemiBold', 500);

@include font-face('Poppins-Regular', $path+'Poppins-Regular/Poppins-Regular', 400);
@include font-face('Poppins-Medium', $path+'Poppins-Medium/Poppins-Medium', 500);
@include font-face('Poppins-SemiBold', $path+'Poppins-SemiBold/Poppins-SemiBold', 500);

@include font-face('BebasKai', $path+'BebasKai/BebasKai', 400);
@include font-face('Bebas-NeueBook', $path+'Bebas-NeueBook/Bebas-NeueBook', 400);

@include font-face('NeueMontreal-Light', $path+'NeueMontreal/NeueMontreal-Light', 200);
@include font-face('NeueMontreal-Regular', $path+'NeueMontreal/NeueMontreal-Regular', 400);
@include font-face('NeueMontreal-Medium', $path+'NeueMontreal/NeueMontreal-Medium', 500);
@include font-face('NeueMontreal-Bold', $path+'NeueMontreal/NeueMontreal-Bold', 600);

