@import "src/styles/variables";

.wrapper {
  background: #fff;
  height: 100%;

  .header {
    position: relative;
    padding: 287px 0;
    text-align: center;
    background: url("/assets/images/bg-resources.jpg") no-repeat center;
    background-size: cover;
    color: #fff;

    &::before {
      content: '';
      background: rgba(0, 0, 0, 0.8);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .text-wrapper {
      position: relative;
      width: 100%;
      padding: 2rem 0;
    }

    .sub_title {
      font-family: $NeueMontreal-regular;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.02em;
      color: $red;
    }

    .title {
      font-family: $NeueMontreal-regular;

      font-size: 64px;
      font-weight: 500;
      line-height: 77px;
      letter-spacing: 0.02em;
      max-width: 767px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      margin-bottom: 40px;
    }

    .description {
      font-family: $NeueMontreal-regular;
      max-width: 864px;
      margin-left: auto;
      margin-right: auto;
      color: rgba(#fff, 0.5);
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.02em;

      b {
        font-family: $NeueMontreal-regular;
        color: #fff;
        font-weight: 400;
      }
    }
  }

  .scroll_link {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    font-family: $NeueMontreal-regular;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;

    .scroll_link_icon {
      color: $red;
      font-size: $font-size-large;
    }
  }

  .card_wrapper {
    display: flex;
    flex-direction: column;
    gap: 56px;
    padding-top: 80px;
    padding-bottom: 146px;

    .card {
      .card_title {
        font-family: $NeueMontreal-medium;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.02em;
      }

      .card_content {
        font-family: $NeueMontreal-regular;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.02em;
        color: rgba(#000, 0.4);
        margin-top: 12px;
        margin-bottom: 24px;
      }
    }
  }

  .link_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;

    .link_item {
      font-family: $NeueMontreal-regular;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: $red;
      text-decoration: none;
      position: relative;
      display: flex;
      align-items: center;

      &::after {
        content: '';
        height: 20px;
        width: 1px;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        right: -12px;
        right: -12px;
        user-select: none;
      }

      &:last-child::after {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    .header {
      .sub_title {
        font-size: 20px;
        line-height: normal;
      }

      .title {
        font-size: 42px;
        line-height: normal;
        max-width: 478px;
      }

      .description {
        max-width: 647px;
        font-size: 16px;
        line-height: 28px;
      }
    }

    .card_wrapper {
      padding-left: 36px;
      padding-right: 36px;
      padding-top: 40px;
      padding-bottom: 120px;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    .header {
      padding: 200px 20px;

      .sub_title {
        font-size: 1rem;
        line-height: normal;
      }

      .title {
        font-size: 2rem;
        line-height: normal;
        max-width: 100%;
      }

    }

    .scroll_link {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .card_wrapper {
      padding: 30px 4px 80px 4px;
    }
  }
}


@media screen and (max-height: 768px) {
  .wrapper {
    .header {
      padding: 30px 20px;
      min-height: 90vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .sub_title {
        font-size: 1rem;
        line-height: normal;
      }

      .title {
        font-size: 2rem;
        line-height: normal;
        margin-bottom: 20px;
        // max-width: 270px;
        min-width: 100%;
      }

      .description {
        font-size: 1rem;
        line-height: normal;
      }

    }


  }
}