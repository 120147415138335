// ====================================
// Reset styles
// ====================================
@mixin reset($type: null) {
  @if $type == spacing { margin: 0; padding: 0; }
  @if $type == list { margin: 0; padding: 0; list-style: none;
    & li { margin: 0; padding: 0; }
  }
  @if $type == everything { float: none; position: static; margin: 0; padding: 0; background-color: transparent; border: none; list-style: none; }
}

// ====================================
// String Replace
// ====================================
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// ====================================
// Font Face
// ====================================
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff ttf svg) {
  $src: null;

  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin xsm {
  //@media screen and (max-width: 576px) { @content; }
  @media screen and (max-width: 600px) { @content; }
}
@mixin sm {
  @media screen and (max-width: 830px){ @content; }
}
@mixin md {
  @media screen and (max-width: 991px) { @content; }
}
@mixin lg {
  @media screen and (max-width: 1280px){ @content; }
}
@mixin laptop {
  @media screen and (max-width: 1450px)  { @content; }
}
@mixin max_xlg {
  @media screen and (max-width: 1500px) { @content; }
}
@mixin xlg {
  @media screen and (min-width: 1200px) { @content; }
}
@mixin full_hd {
  @media screen and (min-width: 1500px) { @content; }
}

@mixin title_font() {
  @include laptop() {
    font-size: 46px;
    line-height: 50px;
  }
  @include md() {
    font-size: 42px;
    line-height: 55px;
  }
  @include sm() {
    font-size: 38px;
    line-height: 50px;
  }
  @include xsm() {
    font-size: 24px;
    line-height: 30px;
  }
}

@mixin subtitle_font() {
  @include laptop() {
    font-size: 20px;
    line-height: 25px;
  }
  @include lg() {
    font-size: 19px;
    line-height: 22px;
  }
  @include md() {
    font-size: 18px;
    line-height: 20px;

  }
  @include sm() {
    font-size: 16px;
    line-height: 18px;
  }
  @include xsm() {
    font-size: 14px;
    line-height: 14px;
  }
}
@mixin descrp_font() {
  @include laptop() {
    font-size: 16px;
    line-height: 24px;
  }
  @include lg() {
    font-size: 15px;
  }
  @include md() {
    font-size: 15px;
    line-height: 24px;
  }
  @include sm() {
    font-size: 14px;
    line-height: 22px;
  }
  @include xsm() {
    font-size: 14px;
    line-height: 22px;
  }
}