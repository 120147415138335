@import './styles/mixins';
@import './styles/variables';
@import './styles/fonts';

:root {
  --headerHeight: 80px;
  --previewButtonHeight: 40px;
  --configuratorSidebarW: 600px;
  @include full_hd() {
    --configuratorSidebarW: 500px;
  }
  @include max_xlg() {
    --configuratorSidebarW: 450px;
  }
  @include laptop() {
    --headerHeight: 60px;
    --configuratorSidebarW: 400px;
  }
  @include lg() {
    --configuratorSidebarW: 350px;
  }
  @media screen and (orientation: landscape ) and (max-height: $mobile-height) {
    --headerHeight: 50px;
    --previewButtonHeight: 30px;

  }
}

body {
  @include reset("everything");
  display: flex;
  flex-direction: column;
  flex: 1 0;
  box-sizing: border-box;
  touch-action: pan-x pan-y;
  scroll-behavior: smooth;
  padding: 0 !important;

  a {
    cursor: pointer;
  }

  button {
    cursor: pointer;
  }

  img, svg {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #f00;
    border-radius: 3px;
    outline: 1px solid #ced4d9;
    height: 20px;
  }

  * {
    box-sizing: border-box;

  }
  .main {
    font-size: $font-size;
    position: relative;
    height: 100%;
    overflow-y: visible !important;
    //overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #f00;
      border-radius: 3px;
      outline: 1px solid #ced4d9;
      height: 20px;
    }
  }
}

@media screen and (orientation: landscape) {
  body {
    touch-action: pan-x pan-y;
    .main {
      overflow-y: auto;
    }
  }
}


.container {
  padding-left: 12.5%;
  padding-right: 12.5%;
  @include sm {
      padding-left: 0;
      padding-right: 0;
  }
}

.___ {
  width: 60px;
  height: 1px;
  background-color: $red;
  //margin: auto;
}

.bttn {
  border-radius: 35px;
  font-family: $muli-regular;
  position: relative;
  background-color: $white;
  border: solid 1px #dddddd;
  text-decoration: none;
  color: #000000;
  padding-top: 23px;
  height: 70px;
  width: 240px;
  display: block;
  text-align: center;
  .right_arrow {
      position: absolute;
      top: 39%;
      right: 10%;
  }
}

.section_header {
  text-align: center;
  font-weight: 500;
  padding: 48px 0;
  font-family: $poppins-regular;
  letter-spacing: 0.45px;
  @include sm {
      font-size: 30px;
      letter-spacing: 0.75px
  }
}

.section_title {
  font-size: 48.5px;
  font-family: $muli-extralight;
  font-weight: 200;
  @include md {
      width: 443px;
      font-size: 48px;
      line-height: 60px;
      letter-spacing: 0.89px;
  }
  @include sm {
      width: fit-content;
  }
}

.section_text {
  font-size: 16px;
  font-family: $muli-regular;
  font-weight: 300;
  line-height: 1.88;
  letter-spacing: 0.4px;
  color: #444444;
  @include md {
      font-family: Muli;
      font-size: 24px;
      line-height: 40px;
      letter-spacing: 0.6px;
  }
}


.info_section {
  position: relative;
  height: 1000px;
  @include md {
      height: fit-content;
  }
  .container {
      //padding-right: 0;
      display: flex;
      align-items: center;
      height: 100%;
      @include md {
          flex-direction: column;
      }
  }
  .text_block {
      z-index: 2;
      @include md {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 100px;
          padding-bottom: 100px;

      }
      .label {
        font-family: $poppins-semibold;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 2.85px;
        margin-bottom: 48px;
      }

      .section_title {
          font-size: 40.5px;
          width: 336px;
          @include md {
              width: fit-content;
          }
      }
      .section_text {
          width: 545px;
          @include sm {
              width: fit-content;
          }
      }
      .___ {
          margin: 0;
          margin-bottom: 49px;
          margin-top: 41px;
      }
  }
  .decorative_img {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      @include md {
          position: initial;
          img {
              width: 100%;
          }
      }
  }
}
